import React, { useEffect, useState } from "react";
import { SiMedium } from "react-icons/si";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaBell, FaUserCircle } from "react-icons/fa";
import Suzy from "../assets/img/suzy.jpg";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const isLoggedIn = useSelector((state) => state.AuthReducer?.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGIN", payload: false });
  };
  const handleSmoothScroll = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href").split("#")[1];
    navigate("/main", { replace: true });
    setTimeout(() => {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    }, 0);
  };
  return (
    <header className={`header ${isSticky ? "sticky" : ""}`} id="header">
      <div className="header-inner">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12">
                <div className="logo">
                  <a href="/main">
                    <SiMedium size={40} />
                    ContosoBestLife
                  </a>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-12">
                <div className="main-menu">
                  <nav className="navigation">
                    <ul className="nav menu">
                      <li className="active">
                        <a
                          href="/main#slider-section"
                          onClick={handleSmoothScroll}
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        {/* <a
                          href="#schedule-section"
                          onClick={handleSmoothScroll}
                        >
                          Providers
                        </a> */}
                        <a
                          href="/main#schedule-section"
                          onClick={handleSmoothScroll}
                        >
                          Providers
                        </a>
                      </li>
                      <li>
                        {/* <a href="#feautes-section" onClick={handleSmoothScroll}>
                          Services
                        </a> */}
                        <a
                          href="/main#feautes-section"
                          onClick={handleSmoothScroll}
                        >
                          Services
                        </a>
                      </li>
                      <li>
                        <a
                          href="main#support-section"
                          onClick={handleSmoothScroll}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <IconGroup>
                    {isLoggedIn ? (
                      <>
                        <ProfileIcon
                          onClick={() => handleNavigation("/profile")}
                        >
                          <ProfilePicture src={Suzy} alt="Profile" />
                        </ProfileIcon>
                        <LoginButton
                          className="btn-link"
                          onClick={handleLogout}
                        >
                          <IoIosLogOut size={30} onClick={handleLogout} /> Sign
                          Out
                        </LoginButton>
                      </>
                    ) : (
                      <LoginButton onClick={() => handleNavigation("/login")}>
                        <IoIosLogIn size={20} /> Sign In
                      </LoginButton>
                    )}
                  </IconGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const ProfilePicture = styled.img`
  width: 60px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid black;
  padding: 2px;
  &:hover {
    background: #1a76d1;
  }
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const LoginButton = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 475px;
  height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;

  &:hover {
    background-color: #0056b3;
  }
`;
const IconButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  color: #2e2e2e;
  font-size: 20px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #f1f1f1;
  }
`;

const ProfileIcon = styled(IconButton)`
  background: linear-gradient(135deg, #d6e1ff, #f8d7ff);
`;

export default Navbar;
