import { combineReducers } from "redux";

import userInfoReducer from "./userInfoReducer";
import pageReducer from "./pageReducer";
import authReducer from "./authReducer";

export default combineReducers({
  UserInfoReducer: userInfoReducer,
  PageReducer: pageReducer,
  AuthReducer: authReducer,
});
