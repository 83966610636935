import React, { useEffect, useState } from "react";
import "../styles/style.css";
import Slider from "react-slick";
import logo from "../assets/img/logo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../assets/img/slider.jpg";
import slider2 from "../assets/img/slider2.jpg";
import slider3 from "../assets/img/slider3.jpg";
import section from "../assets/img/section-img.png";
import { RiMentalHealthFill } from "react-icons/ri";
import { TbRibbonHealth } from "react-icons/tb";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa6";
import {
  FaAngleRight,
  FaAngleLeft,
  FaPhone,
  FaEnvelope,
  FaHeadset,
  FaComments,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ChatbotPopup from "../components/chatbotPopup.js";

function Main() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow next-arrow custom-arrow" onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow prev-arrow custom-arrow" onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => <div className="slick-dot"></div>,
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section id="slider-section" className="slider">
        <div className="slider-container">
          <Slider {...sliderSettings} className="hero-slider">
            <div className="single-slider">
              <img src={slider2} alt="Slider 2" className="slider-image" />
              <div className="slider-content">
                <h1 style={{ color: "white" }}>
                  Find a <span className="highlight">Healthcare</span> Provider
                </h1>
                <p style={{ color: "white" }}>
                  Experience seamless coordination of care with our integrated
                  health services, designed to provide you with personalized
                  treatment plans that address all aspects of your health.
                </p>
                <div className="button">
                  <a href="#" className="btn btn-primary">
                    Get Appointment
                  </a>
                  <a href="#" className="btn btn-secondary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="single-slider">
              <img src={slider1} alt="Slider 1" className="slider-image" />
              <div className="slider-content">
                <h1>
                  We Provide <span className="highlight">Medical</span> Services
                  That You Can <span className="highlight">Trust!</span>
                </h1>
                <p>
                  Your health is our priority, and we deliver reliable,
                  high-quality medical care you can count on.
                </p>
                <div className="button">
                  <a href="#" className="btn btn-primary">
                    Get Appointment
                  </a>
                  <a href="#" className="btn btn-secondary">
                    About Us
                  </a>
                </div>
              </div>
            </div>
            <div className="single-slider">
              <img src={slider3} alt="Slider 3" className="slider-image" />
              <div className="slider-content">
                <h1>
                  Quality <span className="highlight">Healthcare</span> Services
                  For Your <span className="highlight">Family!</span>
                </h1>
                <p>
                  We offer comprehensive healthcare services designed to meet
                  the needs of your entire family, ensuring accessible and
                  reliable care at every stage of life.
                </p>
                <div className="button">
                  <a href="#" className="btn btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section id="schedule-section" className="schedule">
        <div className="container">
          <div
            className="schedule-inner"
            style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
          >
            <div
              className="single-schedule"
              style={{
                flex: "1 1 30%",
                boxSizing: "border-box",
                padding: "15px",
              }}
            >
              <div className="inner">
                <div className="icon">
                  <i className="fa fa-ambulance"></i>
                </div>
                <div className="single-content">
                  <span>Wellness program</span>
                  <h4>Mental health</h4>
                  <p>
                    Wellness program insights, including educational content on
                    preventive care, mental health, and fitness strategies.
                  </p>
                  <a href="#">
                    LEARN MORE<i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="single-schedule"
              style={{
                flex: "1 1 30%",
                boxSizing: "border-box",
                padding: "15px",
              }}
            >
              <div className="inner">
                <div className="icon">
                  <i className="icofont-prescription"></i>
                </div>
                <div className="single-content">
                  <span> Insurance providers</span>
                  <h4>Partnerships</h4>
                  <p>
                    Established partnerships with major insurance providers in
                    Canada and the U.S. to make wellness services accessible to
                    over 1 million covered lives.
                  </p>
                  <a href="#">
                    LEARN MORE<i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="single-schedule"
              style={{
                flex: "1 1 30%",
                boxSizing: "border-box",
                padding: "15px",
              }}
            >
              <div className="inner">
                <div className="icon">
                  <i className="icofont-ui-clock"></i>
                </div>
                <div className="single-content">
                  <span>Employee wellness programs</span>
                  <h4>wellness programs</h4>
                  <ul className="time-sidual">
                    <li className="day">
                      Monday - Friday <span>8.00-20.00</span>
                    </li>
                    <li className="day">
                      Saturday <span>9.00-18.30</span>
                    </li>
                    <li className="day">
                      Monday - Thursday <span>9.00-15.00</span>
                    </li>
                  </ul>
                  <a href="#">
                    LEARN MORE<i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feautes section" id="feautes-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Smart Solutions. Healthy Results.</h2>
                <FaHandHoldingHeart size={40} />
                <h3>
                  ContosoBestLife bridges the gap between modern wellness and
                  traditional healthcare by integrating evidence-based, insured
                  wellness services with innovative technology. It provides a
                  seamless way for individuals to access covered wellness
                  benefits while staying motivated through gamification.
                </h3>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: "flex" }}>
            <div className="col-lg-4 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <RiMentalHealthFill />
                </div>
                <h3>Emergency Help</h3>
                <p>
                  An Unprecedented Spike: Overdose deaths in the U.S. exceeded
                  100,000 for the first time in the yearlong period ending in
                  April 2021. The
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <TbRibbonHealth />
                </div>
                <h3>Mental Health</h3>
                <p>
                  Levels of anxiety and depression have increased in the US
                  approximately 10% from pre-COVID-19 numbers.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="single-features last">
                <div className="signle-icon">
                  <MdOutlineHealthAndSafety />
                </div>
                <h3>Medical Treatment</h3>
                <p>
                  The U.S. Preventive Services Task Force (USPSTF) has released
                  a new app and web-based tool to help health care providers and
                  primary care
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="support" id="support-section">
        <div
          className="container"
          style={{ textAlign: "center", padding: "50px 0" }}
        >
          <div
            className="support-inner"
            style={{
              background: "#f9f9f9",
              padding: "30px",
              borderRadius: "8px",
            }}
          >
            <div className="support-icon" style={{ marginBottom: "20px" }}>
              <FaHeadset size={50} style={{ color: "#007bff" }} />
            </div>
            <h2>24/7 Support</h2>
            <p>Always Online - We're here to help you anytime, any day.</p>
            <p>
              Feel free to reach out to us at{" "}
              <a href="tel:18777453218">1 (877) 745-3218</a> or{" "}
              <a href="mailto:info@ContosoBestLife.com">
                info@ContosoBestLife.com
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      <ChatbotPopup />
    </>
  );
}

export default Main;
