import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
import Navbar from "../components/Nav";
import Login from "../pages/Login";
import Profile from "../pages/Profile";

export default function Layout() {
  return (
    <>
      {/* Header  */}
      <Navbar />
      {/* Mine */}
      <div className="App">
        <Routes>
          <Route index element={<Main />} />
          <Route exact path="/" component={<Main />} />
          <Route path="/Main" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      </div>
      {/* Footer Section */}
      <footer className="footer">
        <div className="container">
          <p>&copy; 2024 Softchoice Corporation. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
