import * as types from "../actions/actionTypes";

const initialState = {
  activeCategory: ["All"],
  activeFilter: [],
};

export default function userInfoReducer(
  state = initialState,
  { payload, type }
) {
  switch (type) {
    case types.set_ActiveCategory:
      return {
        ...state,
        activeCategory: payload,
      };
    case types.set_ActiveFilter:
      return {
        ...state,
        activeFilter: payload,
      };
    default:
      break;
  }
  return state;
}
