import React, { useState } from "react";
import "../styles/chatbotPopup.css";
import { useDispatch, useSelector } from "react-redux";

// Chatbot URL for iframe
const chatbotURL = "https://app-eus-custserv-chatbot.azurewebsites.net/";
// Uncomment for local testing
// const chatbotURL = "http://127.0.0.1:50505";

const ChatbotPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={togglePopup} className="chatbot-button">
        Chat
      </button>
      {isOpen && (
        <div className="chatbot-popup">
          <div className="chatbot-header">
            <button onClick={togglePopup} className="close-button">
              &times;
            </button>
          </div>
          <iframe
            src={chatbotURL}
            title="Chatbot"
            className="chatbot-iframe">
          </iframe>
        </div>
      )}
    </div>
  );
};

export default ChatbotPopup;
