import React from "react";
import styled from "styled-components";

import * as types from "../state-management/actions/actionTypes";
import { FaLocationDot, FaStar, FaCircleCheck } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";
import Suzy from "../assets/img/suzy.jpg";

const Profile = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: types.set_ActivePage,
      payload: "Profile",
    });
  }, [dispatch]);

  return (
    <ProfileContainer>
      <ProfileInfo>
        <ProfilePicture src={Suzy} alt="Profile" />
        <ProfileName>Suzy MacAllister ​</ProfileName>
      </ProfileInfo>
      <Title>Contact Information</Title>
      <ProfileStats>
        <ProfileStatItem>
          <ProfileStatLabel>Age</ProfileStatLabel>
          <ProfileStatValue>34</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel>Gender</ProfileStatLabel>
          <ProfileStatValue>Female</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel> Location </ProfileStatLabel>
          <ProfileLocation>
            <FaLocationDot /> Albany, New York
          </ProfileLocation>
        </ProfileStatItem>
      </ProfileStats>
      <Title>Account Details</Title>

      <ProfileStats>
        <ProfileStatItem>
          <ProfileStatLabel>Email</ProfileStatLabel>
          <ProfileStatValue>suzymac@email.com</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel>Phone</ProfileStatLabel>
          <ProfileStatValue>(518) 555-2020</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel> Occupation </ProfileStatLabel>
          <ProfileLocation>Teacher</ProfileLocation>
        </ProfileStatItem>
      </ProfileStats>
      <Title>Mental Health Information</Title>

      <ProfileStats>
        <ProfileStatItem>
          <ProfileStatLabel>Primary Diagnosis</ProfileStatLabel>
          <ProfileStatValue>Anxiety</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel>Secondary Diagnosis</ProfileStatLabel>
          <ProfileStatValue>Depression</ProfileStatValue>
        </ProfileStatItem>
        <ProfileStatItem>
          <ProfileStatLabel> Assigned Therapist </ProfileStatLabel>
          <ProfileLocation>N/A</ProfileLocation>
        </ProfileStatItem>
      </ProfileStats>
      <ProfileSummary>
        <Title>Bio</Title>
        <div>
          Suzy is struggling to find reliable mental health care while juggling
          work responsibilities and caring for her brother Kevin. ​ Frustrated
          by past experiences, Suzy feels overwhelmed by the complex healthcare
          system and distrusting providers. Despite her challenges, she's
          determined to improve her mental well-being.
        </div>
      </ProfileSummary>
    </ProfileContainer>
  );
};
const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 0 30px 0;
`;

const ProfileSummary = styled.div`
  margin-top: 20px;
`;

const Title = styled.h3`
  margin-bottom: 5px;
  color: #1a76d1;
`;
const ProfileInfo = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const ProfilePicture = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const ProfileName = styled.h1`
  font-size: 20px;
  margin: 10px 0 5px;
  color: #1a76d1;
`;

const ProfileLocation = styled.p`
  font-size: 14px;
  color: gray;
`;

const ProfileStats = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2px 0 20px 0;
  border: 7px solid #e0e0e0;
  padding: 10px;
  border-radius: 20px;
`;

const ProfileStatItem = styled.div`
  text-align: center;
`;

const ProfileStatLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const ProfileStatValue = styled.p`
  font-size: 14px;
  color: gray;
`;

export default Profile;
