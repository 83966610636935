import React, { useState } from "react";
import "./styles/App.css";
import Layout from "./layouts/Layout";
import Login from "./pages/Login";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.AuthReducer?.isLoggedIn);
  const handleLogin = (username, password) => {
    if (username === "test" && password === "123") {
      dispatch({ type: "LOGIN" });
    } else {
      alert("Incorrect username or password");
    }
  };

  // return <>{isLoggedIn ? <Layout /> : <Login onLogin={handleLogin} />}</>;
  return <>{<Layout />}</>;
}

export default App;
